import React from "react";
import { Layout, PortfolioNav, PortfolioHeader } from "../../../components";
import locales from "../../../constants";

const Horizon = ({ pageContext: { slug } }) => {
  const lang = "en";
  const nav = {
    initialSlideIndex: 1,
  };
  return (
    <Layout
      seo={{
        title: "Horizon",
        href: slug,
        lang: "en",
      }}
      header={{
        background:
          "linear-gradient( 45deg, #22967c, #30af93, #2ABA9A, #30af93, #22967c )",
        icons: "#c0a477",
        navClass: "horizon",
        ogImage: require("../../../assets/img/portfolio/horizon_top_back.jpg"),
      }}
      langSwitch={{
        langKey: "pl",
        langSlug: "/projekty/horizon/",
      }}
    >
      <PortfolioHeader name="horizon" height="176" />
      <section className="container-fluid horizon_section_2" id="info">
        <div className="row">
          <div className="col-md-6">
            <div className="inner">
              <h1>Hotel Horizon</h1>
              <ul>
                <li>Website</li>
                <li>Identifications</li>
                <li>Lead camapign</li>
                <li>Advertising banners</li>
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <div className="inner">
              <p>
                WPROST Nieruchomości approached us with a request to support its
                client, the AKME Zdzisław Wiśniewski Capital Group. AKME
                Zdzisław Wiśniewski is the largest company in Poland that
                combines construction services with archaeological activities.
                We were asked to support the company in the realisation of its
                new investment project - a hotel in a residential system,
                located in Szklarska Poręba, one of the most attractive
                locations in.
              </p>
              <p>
                The facility is being built in a four-star standard, providing
                an amazing view of the panorama of the Karkonosze (Giant
                Mountains) - also from the level of infinity pool.
              </p>
              <p>
                Our job was to do .. almost everything. After a detailed
                briefing, we found out that our task was to come up with a name
                for the hotel, key visual, logo and other visual identification
                elements, landing pages for lead generation (condohotel, final
                project page and lead generation websites).
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="container-fluid horizon_section_3">
        <div className="row">
          <div className="col-md-6 col_1">
            <img
              className="img-fluid"
              src={require("../../../assets/img/portfolio/horizon_bar.png")}
              alt=""
            />
            <div className="img_wrapper">
              <div className="img_group">
                <img
                  className="img-fluid"
                  src={require("../../../assets/img/portfolio/horizon_badge.png")}
                  alt=""
                />
              </div>
              <div className="img_group">
                <img
                  className="img-fluid"
                  src={require("../../../assets/img/portfolio/horizon_top_logo.png")}
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="col-md-4 offset-md-2 col_2">
            <h3>Logo</h3>
            <div className="row">
              <div className="col-sm-6">
                <p>CMYK</p>
                <h5>
                  <span>25</span>
                  <span>33</span>
                  <span>59</span>
                  <span>1</span>
                </h5>
              </div>
              <div className="col-sm-6">
                <p>CMYK</p>
                <h5>
                  <span>71</span>
                  <span>65</span>
                  <span>67</span>
                  <span>77</span>
                </h5>
              </div>
              <div className="col-sm-6">
                <p>RGB</p>
                <h5>
                  <span>192</span>
                  <span>164</span>
                  <span>119</span>
                </h5>
              </div>
              <div className="col-sm-6">
                <p>RGB</p>
                <h5>
                  <span>29</span>
                  <span>29</span>
                  <span>27</span>
                </h5>
              </div>
              <div className="col-sm-6">
                <p>HEX</p>
                <h5>c0a477</h5>
              </div>
              <div className="col-sm-6">
                <p>HEX</p>
                <h5>1d1d1b</h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container-fluid horizon_section_4">
        <div className="row row_1">
          <div className="col-md-6 text">
            <div className="inner">
              <h3>Name</h3>
              <p>
                Naming is often quite a complicated process. In the case of this
                project, we had several points of reference - all thanks to the
                fact that both Szklarska Poręba, its surroundings, and the
                facility itself have many distinctive features that we could use
                when developing the concept. We presented our Client with
                proposals resulting from the location of the facility, the
                history of Szklarska Poreba, and those based on the features of
                the facility itself or rather the impressions it will provide to
                its guests. The final name was chosen in the second round of our
                work. Horizon is simply the horizon. It is quite obvious, but at
                the same time few facilities can boast about having such a view
                as the Horizon Hotel & Resort.
              </p>
              <h3>Logo</h3>
              <p>
                Horizon is the horizon, therefore the sign we have designed
                refers to it in the signet, in which we can find a line that
                symbolizes it, falling at its ends. The slight indentation in
                the letter H, in turn, refers to the mountain location of the
                object. The whole logo has a dignified, but at the same time
                relatively modern character (thanks to the simplicity of the
                sign)
              </p>
            </div>
          </div>
          <div className="col-md-6 text-right">
            <div className="scroll_wrapper">
              <span />
              <div className="img_wrapper">
                <img
                  src={require("../../../assets/img/portfolio/horizon_entire_page.png")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row row_2">
          <div className="col-md-6 image">
            <img
              className="img-fluid"
              src={require("../../../assets/img/portfolio/horizon_screen2.png")}
              alt=""
            />
          </div>
          <div className="col-md-6 text">
            <div className="inner">
              <h3>Copywriting</h3>
              <p>
                When preparing to design a website, we also had to prepare its
                content. The final shape was based on the research (data about
                Szklarska Poreba, its history, attractions etc.) and data we had
                on the investment and its business model.
              </p>
            </div>
          </div>
        </div>
        <div className="row row_3">
          <div className="col-md-6 col-image">
            <img
              className="img-fluid"
              src={require("../../../assets/img/portfolio/horizon_screen.png")}
              alt=""
            />
          </div>
          <div className="col-md-6 text">
            <div className="inner">
              <h3>
                Landing page and the final main website of the Horizon Hotel
              </h3>
              <p>
                We knew that we would soon be launching a lead campaign, so we
                approached the design of the landing page and the final page
                with great attention. We have extensive experience in both the
                development and investment industries, but regardless of that we
                started our work with long and careful thought of the design and
                the website’s layout
              </p>
              <p>
                The graphic design was based on the key visual and the website
                itself, as requested by the Client. The site was built on
                WordPress
              </p>
            </div>
          </div>
        </div>
        <div className="row row_4">
          <div className="col-md-6 text">
            <div className="inner">
              <h3>Online and offline marketing campaigns</h3>
              <p>
                We just know how to do it :) We will present the details,
                probably after completing all of our activities and successfully
                selling the entire investment (as we usually do :)). For the
                purposes of the campaign (apart from its design and
                implementation), we made packages of static and dynamic banners,
                and an outdoor advertising project.
              </p>
            </div>
          </div>
          <div className="col-md-6 image">
            <img
              className="img-fluid"
              src={require("../../../assets/img/portfolio/horizon_banners.png")}
              alt=""
            />
          </div>
        </div>
      </section>
      <section className="container-fluid horizon_section_5">
        <div className="row">
          <div className="col-md-5 interactive_banner">
            <img
              className="img-fluid"
              src={require("../../../assets/img/portfolio/img_for_banner.png")}
              alt=""
            />
            <iframe
              src="https://wp.adream.pl/wp-content/themes/adream/assets/banery/horizon/index.html"
              height="800px"
              frameborder="0"
              title="Horizon"
            ></iframe>
          </div>
          <div className="col-md-5 col_banner_info">
            <div className="inner">
              <p>
                An example of dynamic advertising through Facebook ADS campaign.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="container-fluid horizon_section_7">
        <div className="row">
          <div className="col-md-10">
            <div className="inner">
              <p>
                To support the sales office, we have prepared a catalogue
                describing all the advantages of the investment and its
                location. The presented content is a result of the investor's
                direct involvement. Our job was to prepare the final content,
                the development of information architecture and its presentation
                in a form that would be consistent with the style of Horizon
                Hotel & Resort. In addition to the catalogue, we have prepared a
                number of other elements of visual identification, such as
                business cards, e-mail footers, letterheads and folders.
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <img
              className="img-fluid"
              src={require("../../../assets/img/portfolio/identyfikacja.png")}
              alt=""
            />
          </div>
        </div>
      </section>
      <section className="container-fluid horizon_section_6">
        <div className="row">
          <div className="col-md-7 col-image">
            <img
              className="img-fluid"
              src={require("../../../assets/img/portfolio/billboard.png")}
              alt=""
            />
          </div>
          <div className="col-md-4 offset-md-1 text">
            <div className="inner">
              <h3>Key Visual</h3>
              <p>
                Hotel Horizon is an investment in the condo model. This means
                that in the first phase of the project we are searching for
                investors who are interested in buying a hotel room for
                investment purposes (while also acquiring the owner benefits
                package). To emphasize the security of the investment (a
                developer well recognized on the market) and the high standard
                of the hotel, we decided to use the motif of the coat of arms,
                which found its place both in the logo and in the theme of the
                Hotel Horizon brand.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="container-fluid horizon_section_8">
        <div className="row">
          <div className="col-md-9">
            <img
              className="img-fluid"
              src={require("../../../assets/img/portfolio/billboard_2.png")}
              alt=""
            />
          </div>
        </div>
      </section>

      <PortfolioNav data={nav} locales={locales[lang]} />
    </Layout>
  );
};
export default Horizon;
